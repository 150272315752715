import FormContainer from "components/commons/forms/FormContainer";
import { min } from "components/entities/maintenances/maintenanceHelpers";
import { validateMaintenanceEndDate, validatePlanningTime } from "components/validations";
import { baseResources } from "config_infos";
import { MAINTENANCE_TYPE } from "enums";
import { parseErrorMessage } from "helpers/helpers";
import { useEffect } from "react";
import {
  AutocompleteInput,
  Create,
  DateTimeInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useCreate,
  useNotify,
  useRedirect,
  useStore,
} from "react-admin";
import { useFormContext } from "react-hook-form";

export const MaintenanceCreate = () => {
  const [create] = useCreate();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSubmit = (values: any) => {
    create(
      baseResources.maintenance.MAINTENANCE,
      { data: values },
      {
        onError: (error: any) => {
          notify(parseErrorMessage(error), {
            type: "error",
          });
        },
        onSuccess: () => {
          notify("Maintenance created", {
            type: "success",
          });
          redirect(baseResources.maintenance.MAINTENANCE);
        },
      },
    );
  };
  return (
    <Create redirect="list">
      <SimpleForm mode="onBlur" onSubmit={handleSubmit}>
        <FormContainer>
          <SelectInput source="type" choices={MAINTENANCE_TYPE} validate={required()} />

          <DefaultValueSiteReferenceInput />
          <DateTimeInput
            source="start"
            validate={[validatePlanningTime, required()]}
            inputProps={{
              min,
            }}
          />
          <DateTimeInput
            source="end"
            inputProps={{
              min,
            }}
            validate={[validatePlanningTime, validateMaintenanceEndDate, required()]}
          />
          <TextInput source="operator" validate={required()} />
          <TextInput source="comment" multiline />
        </FormContainer>
      </SimpleForm>
    </Create>
  );
};

const DefaultValueSiteReferenceInput = () => {
  const [siteShowId] = useStore(`${baseResources.maintenance.MAINTENANCE}.list.siteShowId`);
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue("site_id", siteShowId);
  }, [siteShowId, setValue]);

  return (
    <ReferenceInput source="site_id" reference={baseResources.sites.SITES} link={"show"} perPage={100}>
      <AutocompleteInput validate={required()} />
    </ReferenceInput>
  );
};
