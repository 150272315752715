import { Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { TabEnum } from "../types";
import GenerationTabForm from "./GenerationTabForm";

function GenerationFormTabs() {
  const [tabValue, setTabValue] = useState<TabEnum>(TabEnum.performance);

  const handleTabChange = (event: React.SyntheticEvent, newValue: TabEnum) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="generation tabs">
          <Tab label="Performance" />
          <Tab label="MSI" />
        </Tabs>
      </Box>

      <GenerationTabForm tabValue={tabValue} index={TabEnum.performance} />
      <GenerationTabForm tabValue={tabValue} index={TabEnum.msi} />
    </Box>
  );
}

export default GenerationFormTabs;
