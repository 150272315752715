import {
  ArrayInput,
  Create,
  FormDataConsumer,
  NumberInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "react-admin";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, IconButton } from "@mui/material";

import { optimizerCountryChoices, optimizerMarketChoices } from "../opti_enums";

export default function CertificationsCreate() {
  return (
    <Create>
      <SimpleForm>
        <TextInput source="certif_name" label="Certification Name" />
        <SelectInput source="market" label="Market" choices={optimizerMarketChoices} />
        <SelectInput source="country" label="Country" choices={optimizerCountryChoices} />

        <FormDataConsumer>
          {({ formData, scopedFormData, getSource }: any) => {
            console.log({ formData, scopedFormData, getSource });

            return (
              <ArrayInput source="certifications_gains">
                <SimpleFormIterator
                  inline
                  disableReordering
                  disableClear
                  removeButton={
                    <IconButton>
                      <DeleteIcon color="error" />
                    </IconButton>
                  }
                  addButton={
                    <Button variant="contained" startIcon={<AddCircleOutlineIcon />} disabled={!formData.market}>
                      ajouter un certifications_gains
                    </Button>
                  }
                  sx={{
                    p: 2,
                    mb: 2,
                    boxShadow:
                      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);",
                  }}
                >
                  {formData.market === "FCR" && <NumberInput source="fcr_gains" label="FCR Gains" />}
                  {formData.market === "AFRR" && <NumberInput source="afrr_gains" label="AFRR Gains" />}
                </SimpleFormIterator>
              </ArrayInput>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
}
