import { getMinutes, parseISO } from "date-fns";
import { PLANNING_TYPE } from "components/entities/tsoTests/testsAfrrRte/helpers";

import * as Yup from "yup";

const planningsValidation = () =>
  Yup.array().of(
    Yup.object().shape({
      afrr_engagement: Yup.number().when("type", {
        is: (type) => type === PLANNING_TYPE.AFRR,
        then: () => Yup.number().required("AFRR engagement is required"),
      }),
      initial_soc_restoration_power: Yup.number().when("type", {
        is: (type) => type === PLANNING_TYPE.AFRR,
        then: () => Yup.number().required("Initial soc restoration power is required"),
      }),
      target_soc: Yup.number().when("type", {
        is: (type) => type === PLANNING_TYPE.SOC_MANAGEMENT,
        then: () => Yup.number().required("Target soc is required").min(0).max(1),
      }),
    }),
  );

export const validationSchema = Yup.object().shape({
  plannings: Yup.object().shape({
    plannings8Racks: planningsValidation(),
    plannings10Racks: planningsValidation(),
  }),
});

export const validateTargetSocRange = (value) => {
  if (value < 0 || value > 1) {
    return "Value must be between 0 and 1";
  }
  return null;
};

export const validatePlanningTime = (datetime) => {
  if (!(datetime instanceof Date)) {
    datetime = parseISO(datetime);
  }
  if (getMinutes(datetime) % 5 !== 0) {
    return "Time must be a multiple of 5 minutes";
  }
  return null;
};

export const validateEndDate = (datetime, values) => {
  if (datetime < values.start_date) {
    return "End date must be after start date";
  }
  return null;
};

export const validateEndDateInFieldArray = (endDatetime, values, { source }) => {
  const parsedSource = source.split(".");
  const index = +parsedSource[1];
  const arrayName = parsedSource[0];
  const startDatetime = values[arrayName][index]?.start_date;
  if (endDatetime < startDatetime) {
    return "End date must be after start date";
  }
  return null;
};

export const validateMaintenanceEndDate = (endDatetime, values) => {
  if (endDatetime < values.start) {
    return "End date must be after start date";
  }
  return null;
};

export const validateFormik5MinPlanningTimeInArray = (datetime, index, values) => {
  if (getMinutes(parseISO(datetime)) % 5 !== 0) {
    return "Time must be a multiple of 5 minutes";
  }
  // const previousEndDate = values?.plannings[index - 1]?.end_date;
  // if (previousEndDate && parseISO(datetime) < parseISO(previousEndDate)) {
  //   return "Start date must be after the previous end date";
  // }
  return null;
};

export const validateStartDate = (value) => {
  if (value) {
    const timezoneOffsetInMinutes = value.getTimezoneOffset() / 60;
    const totalMinutes = value.getHours() * 60 + value.getMinutes();
    const startRestriction = (20 + timezoneOffsetInMinutes) * 60;
    const endRestriction = (24 + timezoneOffsetInMinutes) * 60;

    if (totalMinutes > startRestriction && totalMinutes < endRestriction) {
      return `You cannot plan a test between ${startRestriction / 60}h01 and ${endRestriction / 60 - 1}h59`;
    }

    if (value.getMinutes() % 5 !== 0) {
      return "Minutes have to be a multiple of 5";
    }
  }
  return null;
};

export const parseAndFormatDate = (date) => {
  if (!date) return;
  if (typeof date === "string") {
    date = parseISO(date);
  }
  return date.toISOString().slice(0, 16);
};

export const validateInteger = (value) => {
  if (value == null) {
    return;
  }
  return Number.isInteger(Number(value)) ? undefined : "Ce nombre doit être un entier";
};
