import { Box } from "@mui/material";
import React, { useMemo } from "react";
import {
  BooleanField,
  ChipField,
  Datagrid,
  DateField,
  EditButton,
  Labeled,
  List,
  ReferenceArrayField,
  SelectField,
  ShowButton,
  SingleFieldList,
  TextField,
  useRecordContext,
} from "react-admin";
import { baseResources } from "config_infos";
import { SITE_MODES } from "enums";

type InputContainerProps = {
  source: string;
  Field?: React.ComponentType<any>;
  labelCustomProps?: any;
  fieldCustomProps?: any;
};

const InputContainer = ({ source, labelCustomProps, fieldCustomProps, Field = TextField }: InputContainerProps) => (
  <Box flex={1} pl={2} pr={2}>
    <Labeled source={source} {...labelCustomProps}>
      <Field source={source} fullWidth {...fieldCustomProps} />
    </Labeled>
  </Box>
);

const SiteRowExpand = () => {
  const record = useRecordContext();
  const siteModeOptions = useMemo(() => SITE_MODES.map(({ id, name }: any) => ({ id, name })), []);
  if (!record) return null;

  return (
    <Box display={{ xs: "block", sm: "flex" }}>
      <InputContainer source="valorization_start_date" Field={DateField} />
      <InputContainer source="zipcode" />
      <InputContainer source="efficiency" />
      <InputContainer source="aggregator_module_healthcheck_url" />
      <InputContainer source="network_ip" />
      <InputContainer source="mode" Field={SelectField} fieldCustomProps={{ choices: siteModeOptions }} />
      <InputContainer source="useful_energy_at_1mw" />
    </Box>
  );
};

export const SiteList = () => {
  return (
    <List sort={{ field: "created_at", order: "DESC" }}>
      <Datagrid
        sort={{ field: "created_at", order: "DESC" }}
        rowClick="expand"
        expand={<SiteRowExpand />}
        bulkActionButtons={false}
        sx={{
          "& .RaDatagrid-expandedPanel": {
            backgroundColor: "lightGrey",
          },
        }}
      >
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceArrayField source="group_ids" reference={baseResources.sites.GROUPS} sortable={false}>
          <SingleFieldList linkType="show">
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField source="country" />
        <TextField source="dso" />
        <BooleanField source="iecharge" label="IECharge" />
        <TextField source="rte_code" label={"Tranche TM RTE"} />
        <TextField source="site_operator" />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
