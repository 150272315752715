import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Alert, Card, List as MaterialUIList, Stack } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import PlanningMaskChart from "components/charts/planning/PlanningMaskChart";
import { PaginationType } from "types";
import { baseResources } from "config_infos";
import useFetchPaginatedResource from "hooks/useFetchPaginatedResource";
import { Fragment, useCallback, useState } from "react";
import {
  Button,
  CreateButton,
  Datagrid,
  DateField,
  List,
  ListBase,
  Loading,
  Pagination,
  ReferenceField,
  TextField,
  useDataProvider,
  useListContext,
  useRecordContext,
} from "react-admin";

export default function MasksByGroupList() {
  const dataprovider = useDataProvider();
  const [isListMode, setIsListMode] = useState(true);

  const toggleViewMode = () => {
    setIsListMode((prevState) => !prevState);
  };

  const fetchEdrs = useCallback(
    async ({ page, size }: PaginationType) => {
      const config = {
        sort: { field: "id", order: "ASC" },
        pagination: { page: page, perPage: size },
        filter: {
          type: "rte_edp",
        },
      };
      const { data: items, total } = await dataprovider.getList(baseResources.sites.GROUPS, config);
      return { items, total: total as number };
    },
    [dataprovider],
  );

  const { error: edpError, loading: edpLoading, items: rawEdrs } = useFetchPaginatedResource(fetchEdrs, 100);
  const edps = rawEdrs && rawEdrs.length > 0 ? rawEdrs : [];

  const [openedGroup, setOpenedGroup] = useState<number | null>(null);

  const handleClick = (id: number) => {
    setOpenedGroup(id);
  };

  if (edpLoading) return <Loading />;

  return (
    <>
      <Stack direction="row" justifyContent={"space-between"}>
        <Button
          label={isListMode ? "View Graph" : "View List"}
          variant="contained"
          size="large"
          onClick={toggleViewMode}
          sx={{ mt: 2, maxWidth: 200 }}
        />
        <CreateButton />
      </Stack>
      {edpError && (
        <Alert severity="error" sx={{ m: 2 }}>
          {edpError.message}
        </Alert>
      )}
      {/* <ListBase filter={{ type: "rte_edp" }} perPage={50} resource={baseResources.sites.GROUPS} disableSyncWithLocation>
        <Datagrid
          header={<></>}
          bulkActionButtons={false}
          rowClick="expand"
          expand={<MasksListInDatagrid />}
          expandSingle
          sx={{
            "& .RaDatagrid-root": {
              boxShadow: 4,
            },
          }}
        >
          <TextField source="name" sortable={false} />
        </Datagrid>
      </ListBase> */}
      {isListMode ? (
        <MaterialUIList>
          {edps.map((edp: any) => {
            const isOpen = openedGroup === edp.id;
            const elevation = isOpen ? 4 : 1;
            return (
              <Fragment key={edp.id}>
                <ListItemButton
                  onClick={() => handleClick(edp.id)}
                  sx={{
                    bgcolor: isOpen ? "primary.main" : "background.paper",
                    m: 2,
                    boxShadow: elevation,
                  }}
                >
                  <ListItemText primary={`${edp.name} planning masks`} />
                  {isOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                  <Card
                    elevation={elevation}
                    sx={{
                      ml: 4,
                      mr: 4,
                    }}
                  >
                    <MasksList siteIds={edp.site_ids} />
                  </Card>
                </Collapse>
              </Fragment>
            );
          })}
        </MaterialUIList>
      ) : (
        <PlanningMaskChart />
      )}
    </>
  );
}

const MasksListInDatagrid = () => {
  const { site_ids } = useRecordContext();
  return (
    <ListBase
      filter={{ site_id__in: site_ids.toString(), end_date__gt: new Date().toISOString() }}
      perPage={50}
      resource={baseResources.plannings.PLANNING_MASKS}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="site_id" label="Site ID" />
        <ReferenceField source="site_id" reference={baseResources.sites.SITES} link={"show"}>
          <TextField source="name" />
        </ReferenceField>

        <TextField source="operating_mode" />
        <DateField showTime source="start_date" locales="fr-FR" />
        <DateField showTime source="end_date" locales="fr-FR" />
        <TextField source="operator_id" />
      </Datagrid>
      <Pagination />
    </ListBase>
  );
};
const MasksList = ({ siteIds }: { siteIds: number[] }) => {
  return (
    <ListBase filter={{ site_id__in: siteIds.toString(), end_date__gt: new Date().toISOString() }} perPage={50}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="site_id" label="Site ID" />
        <ReferenceField source="site_id" reference={baseResources.sites.SITES} link={"show"}>
          <TextField source="name" />
        </ReferenceField>

        <TextField source="operating_mode" />
        <DateField showTime source="start_date" locales="fr-FR" />
        <DateField showTime source="end_date" locales="fr-FR" />
        <TextField source="operator_id" />
      </Datagrid>
      <Pagination />
    </ListBase>
  );
};
