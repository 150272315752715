import axios from "axios";
import { baseResources } from "config_infos";

export const socRelatedFieldValidation = (value: number, source: string, allValues: any) => {
  const { soc_reserve_inf, soc_inf, soc_sup, soc_reserve_up } = allValues;
  switch (source) {
    case "soc_reserve_inf":
      if (soc_reserve_up && value >= soc_reserve_up) {
        return "Must be lower than soc_reserve_up";
      }
      break;
    case "soc_inf":
      if (soc_sup && value >= soc_sup) {
        return "Must be lower than soc_sup";
      }
      break;
    case "soc_sup":
      if (soc_inf && value <= soc_inf) {
        return "Must be higher than soc_inf";
      }
      break;
    case "soc_reserve_up":
      if (soc_reserve_inf && value <= soc_reserve_inf) {
        return "Must be higher than soc_reserve_inf";
      }
      break;

    default:
      break;
  }
  return undefined;
};

export const socValidations = (value: number, allValues: any, inputInfos: any) => {
  if (!value) {
    return undefined;
  }
  if (value <= 0 || value >= 100) {
    return "Must be between 0 and 100";
  }
  const { source } = inputInfos;
  return socRelatedFieldValidation(value, source, allValues);
};

type SiteValidationsErrors = {
  group_ids?: string;
};

export const siteValidations = async (values: any) => {
  const errors: SiteValidationsErrors = {};
  if (!values) {
    return errors;
  }
  if (values.group_ids && values.group_ids.length > 0) {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_SITE_API_URL}/${baseResources.sites.GROUPS}all/?`,
      params: {
        id__in: values.group_ids.join(","),
      },
    });
    if (response.data.length !== values.group_ids.length) {
      errors.group_ids = "Some groups are not valid";
    }
    const groupTypes = response.data.map((group: any) => group.type);
    if (new Set(groupTypes).size !== groupTypes.length) {
      errors.group_ids = "You cannot asign the groups of the same type to a site";
    }
  }
  return errors;
};
