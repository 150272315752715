import { Alert, ListItem, ListItemIcon, ListItemText, List as MuiList, Typography } from "@mui/material";
import axios, { AxiosError } from "axios";
import { baseResources } from "config_infos";
import { getAxiosCustomErrorMessage } from "errors/handlers";
import useStatusAware from "hooks/useStatusAware";
import { useCallback, useEffect, useMemo, useState } from "react";

import ColorTag from "components/commons/tags/ColorTag";
import { format, parseISO } from "date-fns";
import {
  Datagrid,
  DateField,
  FunctionField,
  RaRecord,
  ReferenceField,
  TextField,
  useListContext,
  useNotify,
  useRefresh,
} from "react-admin";
import { ButtonActionType, ConfigsBySiteType, PerformanceChronicleType } from "../types";
import PerformanceActionButtons from "./PerformanceActionButtons";
import PerformanceResultSubList from "./PerformanceResultSubList";
import StatusField from "./StatusField";

const PerformanceDatagrid = () => {
  const refresh = useRefresh();
  const { data } = useListContext();
  const notify = useNotify();
  const { loading, setLoading, error, setError } = useStatusAware(false);
  const [configsBySite, setConfigsBySite] = useState<ConfigsBySiteType>({});

  const getSiteConfigCall = useCallback(
    async (siteId: number) => {
      try {
        const { data } = await axios({
          method: "get",
          url: `${process.env.REACT_APP_SITE_API_URL}/${baseResources.sites.SITES}/${siteId}/configurations/`,
        });
        return data;
      } catch (error) {
        setError("Error: there was a problem fetching the site configurations");
      }
    },
    [setError],
  );

  const filterPerformanceChroniclesBySite = useCallback((chronicles: PerformanceChronicleType[]) => {
    const filteredChronicles = chronicles.filter((c) => c.validated_at !== null && c.failed_at === null);

    const chroniclesGroupedBySites: { [key: number]: PerformanceChronicleType[] } = {};
    filteredChronicles.forEach((chronicle) => {
      if (!chroniclesGroupedBySites[chronicle.site_id]) {
        chroniclesGroupedBySites[chronicle.site_id] = [];
      }
      chroniclesGroupedBySites[chronicle.site_id].push(chronicle);
    });
    return chroniclesGroupedBySites;
  }, []);

  const prepareData = useCallback(
    (chronicles: PerformanceChronicleType[]) => {
      const chroniclesGroupedBySites = filterPerformanceChroniclesBySite(chronicles);
      const siteIds = Object.keys(chroniclesGroupedBySites);

      setLoading(true);
      Promise.allSettled(siteIds.map((siteId) => getSiteConfigCall(Number(siteId)))).then((results) => {
        setConfigsBySite(() => {
          const configs: { [key: string]: any } = {};
          results.forEach((result, index) => {
            if (result.status === "fulfilled") {
              configs[siteIds[index]] = result.value;
            }
          });
          setLoading(false);
          return configs;
        });
      });
    },
    [getSiteConfigCall, filterPerformanceChroniclesBySite, setLoading],
  );

  const COLORS = useMemo(() => {
    return {
      USED: "green",
      FAILED: "red",
      VALIDATED: "lightgreen",
      PENDING: "lightblue",
      UNDETERMINED: "transparent",
    };
  }, []);

  useEffect(() => {
    if (data) {
      prepareData(data);
    }
  }, [data, prepareData]);

  const handleClick = useCallback(
    (performance: PerformanceChronicleType) => async (action: ButtonActionType) => {
      const baseUrl = `${process.env.REACT_APP_SITE_API_URL}/${baseResources.sites.PERFORMANCE_CHRONICLES}`;
      setLoading(true);
      try {
        await axios({
          method: "post",
          url: `${baseUrl}${performance.id}/${action}/`,
        });
        notify(`${action} status: SUCCESS`, { type: "success" });
        refresh();
      } catch (error) {
        const message = getAxiosCustomErrorMessage(error as AxiosError);
        setError(message);
        notify(`${action} status: ERROR  |  ${message}`, { type: "error" });
      } finally {
        setLoading(false);
      }
    },
    [notify, refresh, setLoading, setError],
  );

  return (
    <>
      {error && (
        <Alert severity="error" sx={{ m: 2 }}>
          <Typography>{error}</Typography>
        </Alert>
      )}
      <MuiList
        sx={{
          display: "flex",
          direction: "row",
          justifyContent: "left",
        }}
      >
        {Object.entries(COLORS).map(([name, bgcolor], index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <ColorTag color={bgcolor} />
            </ListItemIcon>
            <ListItemText>{name}</ListItemText>
          </ListItem>
        ))}
      </MuiList>
      <Datagrid bulkActionButtons={false} expand={<PerformanceResultSubList />}>
        <PerformanceActionButtons handleClick={handleClick} loading={loading} />
        <ReferenceField source="site_id" reference={baseResources.sites.SITES} />
        <TextField source="id" label="Chronicle id" />
        <StatusField source="status" COLORS={COLORS} configsBySite={configsBySite} sortable={false} />
        <FunctionField
          label="Last modified at"
          sortBy="failed_at, -validated_at, -created_at"
          render={(record: RaRecord) => {
            const failedAt = record?.failed_at ? format(parseISO(record.failed_at), "dd/MM/yyyy HH:mm:ss") : null;
            const validatedAt = record?.validated_at
              ? format(parseISO(record.validated_at), "dd/MM/yyyy HH:mm:ss")
              : null;
            return record ? failedAt || validatedAt || "N/A" : "";
          }}
        />

        <TextField source="type" />
        <TextField source="chronicle_filename" />
        <TextField source="chronicle_result" />
        <TextField source="comment" />
        <DateField locales={"fr-FR"} showTime source="start_date" />
        <DateField locales={"fr-FR"} showTime source="end_date" />
      </Datagrid>
    </>
  );
};

export default PerformanceDatagrid;
