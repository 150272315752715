import { baseResources } from "config_infos";
import { GROUP_TYPES } from "enums";
import { parseErrorMessage } from "helpers/helpers";
import { Create, Loading, useCreate, useNotify, useRedirect } from "react-admin";
import GroupBaseForm from "./GroupBaseForm";

export const GroupCreate = () => {
  const [create, { isLoading }]: any = useCreate();
  const redirect = useRedirect();
  const notify = useNotify();

  const handleSubmit = (values: any) => {
    const { country, group_number, ...rest } = values; // eslint-disable-line

    const data = {
      ...rest,
      name: `${GROUP_TYPES.find((groupType) => groupType.value === rest.type)?.name} ${group_number}`,
    };
    create(
      baseResources.sites.GROUPS,
      { data },
      {
        onSuccess: ({ id }: any) => {
          redirect(`/${baseResources.sites.GROUPS}${id}/show`);
        },
        onError: (error: any) => {
          notify(parseErrorMessage(error), { type: "error", autoHideDuration: 10000 });
        },
      },
    );
  };

  if (isLoading) return <Loading />;

  return (
    <Create>
      <GroupBaseForm handleSubmit={handleSubmit} isCreateMode />
    </Create>
  );
};
