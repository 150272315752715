import { List } from "react-admin";
import PerformanceDatagrid from "./PerformanceDatagrid";

const PerformanceList = () => {
  return (
    <List
      sort={{
        field: "failed_at, -validated_at, -created_at",
        order: "DESC",
      }}
      filter={{}}
      perPage={50}
      exporter={false}
    >
      <PerformanceDatagrid />
    </List>
  );
};

export default PerformanceList;
