import { Create, DateTimeInput, NumberInput, SelectInput, SimpleForm } from "react-admin";
import GroupCodeAutocompleteInput from "../GroupCodeAutocompleteInput";
import { optimizerCertificationsTestsTypeChoices } from "../opti_enums";

export default function CertificationsTestsCreate() {
  return (
    <Create>
      <SimpleForm>
        <GroupCodeAutocompleteInput fullWidth />
        <DateTimeInput source="start_date" label="Start Date" fullWidth />
        <DateTimeInput source="end_date" label="End Date" fullWidth />
        <NumberInput source="fcr_engagement" label="FCR engagement" fullWidth />
        <NumberInput source="afrr_engagement" label="AFRRR engagement" fullWidth />
        <NumberInput source="certif_id" label="Certification" fullWidth />
        <SelectInput source="test_type" label="Test Type" choices={optimizerCertificationsTestsTypeChoices} fullWidth />
      </SimpleForm>
    </Create>
  );
}
