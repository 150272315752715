import { ChipField, Datagrid, List, ReferenceArrayField, SingleFieldList, NumberField, DateField } from "react-admin";
import { baseResources } from "config_infos";

//change text fils
export const RTE_GateList = () => (
  <List sort={{ field: "id", order: "DESC" }}>
    <Datagrid rowClick="show">
      <NumberField source="id" />
      <DateField source="application_gate" />
      <NumberField source="deposit_status_code" />
      <DateField source="target_gate" />
      <NumberField source="rte_ticket_id" />
      <NumberField source="revision_id" />
      <ReferenceArrayField source="pa_ids" reference={baseResources.topase.PA} label="Related EDPs">
        <SingleFieldList linkType="show">
          <ChipField source="edp" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField source="pa_ids" reference={baseResources.topase.PA} label="Related PAs">
        <SingleFieldList linkType="show">
          <ChipField source="id" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField source="rte_gate_status_ids" reference={baseResources.topase.RTE_GATE_STATUS} label="Status">
        <SingleFieldList linkType="show">
          <ChipField source="gate_status" />
        </SingleFieldList>
      </ReferenceArrayField>
    </Datagrid>
  </List>
);
