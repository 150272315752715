import { useRecordContext } from "react-admin";

import { SiteMap } from "./SiteMap";

import "react-big-scheduler-stch/lib/css/style.css";

const SiteLocation = () => {
  const record = useRecordContext();
  return <SiteMap latitude={record?.latitude} longitude={record?.longitude} name={record?.city} height="400px" />;
};

export default SiteLocation;
