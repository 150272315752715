import { stringify } from "query-string";
import { fetchUtils } from "react-admin";

export const getToken = () => {
  // try {
  //   const json = localStorage.getItem("auth");
  //   const auth = JSON.parse(json);
  //   return auth.access_token;
  // } catch (e) {
  //   console.warn("could not parse local token");
  //   throw new HttpError("Invliad local token", 401);
  // }
  return "fake_token";
};

export const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  options.headers.append("Authorization", `Bearer ${getToken()}`);
  return fetchUtils.fetchJson(url, options);
};

export default (apiUrl) => ({
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      page: page,
      size: perPage,
      ...params.filter,
    };
    if (field) {
      query.order_by = (order == "ASC" ? "+" : "-") + field;
    }
    if (query.q) {
      query.search = params.filter.q;
      delete query.q;
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const { json } = await httpClient(url);
    return {
      data: json.items,
      total: json.total,
      page: json.page,
      size: json.size,
    };
  },

  getOne: async (resource, params, url = null) => {
    const { json } = url
      ? await httpClient(url)
      : await httpClient(`${apiUrl}/${resource.replace(/\/$/, "")}/${params.id}`);
    return {
      data: json,
    };
  },

  getMany: async (resource, params) => {
    const query = {
      id__in: params.ids,
    };
    const url = `${apiUrl}/${resource}?${stringify(query, {
      arrayFormat: "separator",
      arrayFormatSeparator: ",",
    })}`;
    const { json } = await httpClient(url);
    return { data: json.items };
  },

  getManyReference: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    const { headers, json } = await httpClient(url);
    return {
      data: json,
      total: parseInt(headers.get("content-range").split("/").pop(), 10),
    };
  },

  create: (resource, params) => {
    console.log({ resource, params });

    return httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then((response) => {
      const { json } = response;
      return {
        data: { ...params.data, id: json.id, json },
      };
    });
  },

  update: (resource, params) => {
    return httpClient(`${apiUrl}/${resource.replace(/\/$/, "")}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => {
      if (!("id" in json) && params.id) {
        json.id = params.id;
      }
      return { data: json };
    });
  },

  updateMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const { json } = await httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    });
    return { data: json };
  },

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource.replace(/\/$/, "")}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json })),

  deleteMany: async (resource, params) => {
    const query = {
      ids: JSON.stringify(params.ids),
      soft: true,
    };
    const { json } = await httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "DELETE",
      body: JSON.stringify(params.data),
    });
    return { data: json.deleted_ids };
  },
});
