import { Datagrid, ListContextProvider, NumberField, useList, useRecordContext } from "react-admin";

const PerformanceResultSubList = () => {
  const record = useRecordContext();
  const listContext = useList({ data: record?.performance_chronicle_results || [] });

  return (
    <ListContextProvider value={listContext}>
      <Datagrid
        bulkActionButtons={false}
        sx={{
          "& .RaDatagrid-table": {
            backgroundColor: "lightgrey",
          },
          "& .RaDatagrid-headerCell": {
            backgroundColor: "lightgrey",
          },
        }}
      >
        <NumberField source="power_mw" />
        <NumberField source="soc_min_pct" />
        <NumberField source="soc_max_pct" />
        <NumberField source="soc_bridage_max_pct" />
        <NumberField source="soc_bridage_min_pct" />
        <NumberField source="autonomy_max_bmin_mwh" />
        <NumberField source="autonomy_bmax_min_mwh" />
        <NumberField source="autonomy_bridage_inj_mwh" />
        <NumberField source="autonomy_max_min_inj_mwh" />
        <NumberField source="autonomy_max_min_sout_mwh" />
        <NumberField source="autonomy_bridage_sout_mwh" />
      </Datagrid>
    </ListContextProvider>
  );
};

export default PerformanceResultSubList;
