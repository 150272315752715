import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { format } from "date-fns";
import { FieldArray, Form as FormikForm, FormikProvider, useFormik, useFormikContext } from "formik";
import { useCallback, useMemo } from "react";
import { Toolbar, useCreate, useGetOne } from "react-admin";

import {
  BIDS_ACTIVATION,
  BIDS_CURRENCY,
  BIDS_PRICES_PAYMENT_DIRECTION,
  BIDS_PRICES_TYPE,
  BIDS_PRODUCT_NAME,
  BIDS_TYPE,
  BIDS_ZONES,
  BIDS_ZONE_TYPE,
  MARKET,
  MEASURE_UNIT,
  PRODUCT_TYPE,
} from "enums";
import StatusAware from "components/commons/statuses/StatusAware";
import { getMuiSelectOptions } from "helpers";
import CustomAlert from "components/commons/statuses/CustomAlert";
import { FormikField } from "components/commons/inputs/FormikInput";
import { baseResources } from "config_infos";

export default function BidsCreate() {
  const [create, { isLoading, error }]: any = useCreate(); // eslint-disable-line
  const {
    data: defaultConfig,
    isLoadingConfig,
    errorConfig,
  }: any = useGetOne(baseResources.regelleistung.DEFAULT_CONFIG, { id: "" });

  // eslint-disable-next-line
  const onSubmit = useCallback((values: any, { setValues, setStatus, status }: any) => {
    const data = {
      parallel: true,
      ops: values.bids.map((bid: any) => ({
        path: "/bids",
        method: "POST",
        body: bid,
      })),
    };
    console.log("data:", data);
    // create(
    //   baseResources.regelleistung.BIDS_BATCH,
    //   { data },
    //   {
    //     onSettled: ({ json }: any, error: any) => {
    //       if (error) {
    //         setStatus({
    //           ...status,
    //           global: JSON.stringify(error),
    //         });
    //       }
    //       const { bids: responseBids } = json;
    //       const responseDataInfos = responseBids.reduce(
    //         (acc: any[], { error, index }: any) => {
    //           if (!error) return acc;
    //           return [
    //             ...acc,
    //             {
    //               bid: values.bids[index],
    //               error,
    //             },
    //           ];
    //         },
    //         []
    //       );
    //       if (!responseDataInfos.length) {
    //         navigate(`/${resource}`);
    //       } else {
    //         setValues({
    //           bids: responseDataInfos.map(({ bid }: any) => bid),
    //         });
    //         setStatus({
    //           bids: responseDataInfos.map(({ error }: any) => error),
    //         });
    //       }
    //     },
    //   }
    // );
  }, []);

  const initialValues = {
    bids: [],
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
    initialStatus: {},
  });

  const { values } = formik;

  return (
    <StatusAware error={error || errorConfig} loading={isLoading || isLoadingConfig}>
      <FormikProvider value={formik}>
        <FormikForm>
          {
            <FieldArray
              name="bids"
              render={(arrayHelpers) => <BidSubForm arrayHelpers={arrayHelpers} defaultConfig={defaultConfig} />}
            />
          }

          <Toolbar>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SendIcon />}
              disabled={values.bids.length === 0}
            >
              Déposer les bids
            </Button>
          </Toolbar>
        </FormikForm>
      </FormikProvider>
    </StatusAware>
  );
}

const BidSubForm = ({ arrayHelpers, defaultConfig }: any) => {
  const { values, status }: any = useFormikContext();

  const newBid = useMemo(
    () => ({
      market: defaultConfig?.market || MARKET[0],
      bidType: BIDS_TYPE[0],
      productType: defaultConfig?.productType || PRODUCT_TYPE[0],
      deliveryDate: format(new Date(), "yyyy-MM-dd"),
      productName: BIDS_PRODUCT_NAME[0],
      connectingZone: {
        zoneType: defaultConfig?.zoneType || BIDS_ZONE_TYPE[0],
        zone: defaultConfig?.zone || BIDS_ZONES[0],
      },
      activation: BIDS_ACTIVATION[0],
      offeredQuantity: {
        quantity: 0,
        measureUnit: defaultConfig?.measureUnit || MEASURE_UNIT[0],
      },
      prices: [
        {
          amount: 0,
          measureUnit: defaultConfig?.measureUnit || MEASURE_UNIT[0],
          currency: defaultConfig?.currency || BIDS_CURRENCY[0],
          paymentDirection: BIDS_PRICES_PAYMENT_DIRECTION[0],
          type: BIDS_PRICES_TYPE[0],
        },
      ],
    }),
    [defaultConfig],
  );

  const marketOptions = useMemo(() => getMuiSelectOptions(MARKET), []);
  const bidTypeOptions = useMemo(() => getMuiSelectOptions(BIDS_TYPE), []);
  const productTypeOptions = useMemo(() => getMuiSelectOptions(PRODUCT_TYPE), []);
  const productNameOptions = useMemo(() => getMuiSelectOptions(BIDS_PRODUCT_NAME), []);
  const activationOptions = useMemo(() => getMuiSelectOptions(BIDS_ACTIVATION), []);
  const zoneTypeOptions = useMemo(() => getMuiSelectOptions(BIDS_ZONE_TYPE), []);
  const zoneOptions = useMemo(() => getMuiSelectOptions(BIDS_ZONES), []);
  const measureUnitOptions = useMemo(() => getMuiSelectOptions(MEASURE_UNIT), []);
  const paymentDirectionOptions = useMemo(() => getMuiSelectOptions(BIDS_PRICES_PAYMENT_DIRECTION), []);
  const currencyOptions = useMemo(() => getMuiSelectOptions(BIDS_CURRENCY), []);
  const priceTypeOptions = useMemo(() => getMuiSelectOptions(BIDS_PRICES_TYPE), []);

  const bidsErrors = status.bids;
  const globalErrors = status.global;
  const bidsValues = values.bids;

  return (
    <Grid
      container
      spacing={2}
      sx={{
        marginTop: 1,
      }}
    >
      {globalErrors && (
        <Grid item xs={12}>
          <CustomAlert title={<strong>Error, something went wrong</strong>} subTitle={globalErrors} />
        </Grid>
      )}

      {bidsValues &&
        bidsValues.length > 0 &&
        bidsValues.map((bid: any, index: number) => (
          <Grid
            item
            container
            key={index}
            spacing={2}
            sx={{
              p: 2,
              m: 2,
              borderRadius: 5,
              border: bidsErrors && bidsErrors[index] ? "solid 3px red" : "solid 3px lightgrey",
            }}
          >
            <Grid item container xs={12} wrap={"nowrap"} justifyContent={"space-between"}>
              <Grid item>
                <Typography variant="h5">{`Bid n°${index + 1}`}</Typography>
              </Grid>
              {bidsErrors && bidsErrors[index] && (
                <Grid item>
                  <CustomAlert
                    title={
                      <>
                        Error {bidsErrors[index].status} - <strong>{bidsErrors[index].title}</strong>
                      </>
                    }
                    subTitle={bidsErrors[index].detail}
                  />
                </Grid>
              )}
            </Grid>

            <Grid item xs={4}>
              <FormikField required select name={`bids[${index}].market`} label="Market">
                {marketOptions}
              </FormikField>
            </Grid>
            <Grid item xs={4}>
              <FormikField required select name={`bids[${index}].bidType`} label={"Bid type"}>
                {bidTypeOptions}
              </FormikField>
            </Grid>
            <Grid item xs={4}>
              <FormikField required select name={`bids[${index}].productType`} label={"Product type"}>
                {productTypeOptions}
              </FormikField>
            </Grid>
            <Grid item xs={4}>
              <FormikField required type={"date"} name={`bids[${index}].deliveryDate`} label={"Delivery date"} />
            </Grid>
            <Grid item xs={4}>
              <FormikField required select name={`bids[${index}].productName`} label={"Product name"}>
                {productNameOptions}
              </FormikField>
            </Grid>
            <Grid item xs={4}>
              <FormikField required select name={`bids[${index}].activation`} label={"Activation"}>
                {activationOptions}
              </FormikField>
            </Grid>
            <Grid item xs={12}>
              <Divider textAlign="left">CONNECTING ZONE</Divider>
            </Grid>
            <Grid item xs={4}>
              <FormikField required select name={`bids[${index}].connectingZone.zoneType`} label={"Zone type"}>
                {zoneTypeOptions}
              </FormikField>
            </Grid>
            <Grid item xs={4}>
              <FormikField required select name={`bids[${index}].connectingZone.zone`} label={"Zone"}>
                {zoneOptions}
              </FormikField>
            </Grid>
            <Grid item xs={12}>
              <Divider textAlign="left">OFFERED QUANTITY</Divider>
            </Grid>
            <Grid item xs={4}>
              <FormikField
                required
                name={`bids[${index}].offeredQuantity.quantity`}
                label={"Quantity"}
                inputProps={{
                  type: "number",
                  min: 0,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormikField required select name={`bids[${index}].offeredQuantity.measureUnit`} label={"Measure unit"}>
                {measureUnitOptions}
              </FormikField>
            </Grid>
            <Grid item xs={12}>
              <Divider textAlign="left">PRICES</Divider>
            </Grid>
            <Grid item xs={4}>
              <FormikField
                required
                name={`bids[${index}].prices[0].amount`}
                label={"Amount"}
                inputProps={{
                  type: "number",
                  min: 0,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormikField required select name={`bids[${index}].prices[0].measureUnit`} label={"Measure unit"}>
                {measureUnitOptions}
              </FormikField>
            </Grid>
            <Grid item xs={4}>
              <FormikField required select name={`bids[${index}].prices[0].currency`} label={"Currency"}>
                {currencyOptions}
              </FormikField>
            </Grid>
            <Grid item xs={4}>
              <FormikField
                required
                select
                name={`bids[${index}].prices[0].paymentDirection`}
                label={"Payment direction"}
              >
                {paymentDirectionOptions}
              </FormikField>
            </Grid>
            <Grid item xs={4}>
              <FormikField required select name={`bids[${index}].prices[0].type`} label={"Type"}>
                {priceTypeOptions}
              </FormikField>
            </Grid>
            <Grid item container>
              <Button
                color="error"
                variant="outlined"
                startIcon={<DeleteIcon />}
                onClick={() => arrayHelpers.remove(index)}
                sx={{
                  m: 2,
                }}
              >
                Supprimer le bid
              </Button>
            </Grid>
          </Grid>
        ))}

      <Grid item xs>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => arrayHelpers.push(newBid)}
          sx={{
            marginBottom: 2,
          }}
        >
          Ajouter un bid
        </Button>
      </Grid>
    </Grid>
  );
};
