import { Identifier } from "react-admin";

export const arrayToChoices = (array: Identifier[]) =>
  array.map((item) => ({ id: item.toString(), name: item.toString() }));

export const enumToChoices = (enumObject: object) => {
  return Object.entries(enumObject).map(([key, value]) => ({
    id: value,
    name: key,
  }));
};
