import { useCallback } from "react";

import ColorTag from "components/commons/tags/ColorTag";
import { RaRecord, useRecordContext } from "react-admin";
import { ConfigsBySiteType } from "../types";
import { Tooltip } from "@mui/material";

const StatusField = ({
  source,
  COLORS,
  configsBySite,
  sortable = false,
}: {
  source: string;
  sortable?: boolean;
  COLORS: { [key: string]: string };
  configsBySite: ConfigsBySiteType;
}) => {
  const record = useRecordContext({ source, sortable });
  const getColor = useCallback(
    (currentPerformance: RaRecord) => {
      let rowColor = "transparent";
      const siteConfig = configsBySite[currentPerformance.site_id];
      if (currentPerformance.failed_at) {
        rowColor = COLORS.FAILED;
      } else if (siteConfig) {
        const desiredConfig = siteConfig.desired_configuration;
        const reportedConfig = siteConfig.reported_configuration;

        if (
          desiredConfig &&
          reportedConfig &&
          desiredConfig.performance_chronicle.id !== reportedConfig.performance_chronicle.id &&
          desiredConfig.performance_chronicle.id === currentPerformance.id
        ) {
          rowColor = COLORS.PENDING;
        } else if (reportedConfig && reportedConfig.performance_chronicle.id === currentPerformance.id) {
          rowColor = COLORS.USED;
        } else if (currentPerformance.validated_at) {
          rowColor = COLORS.VALIDATED;
        }
      } else {
        rowColor = COLORS.UNDETERMINED;
      }
      return rowColor;
    },
    [COLORS, configsBySite],
  );
  const color = getColor(record);
  return record ? (
    <Tooltip title="Delete">
      <ColorTag color={color} />
    </Tooltip>
  ) : null;
};

export default StatusField;
