import { Box, Card, Divider, Stack } from "@mui/material";
import axios from "axios";
import RteTmChart from "components/charts/tsoCommunication/rte/RteTmChart";
import RteTsChart from "components/charts/tsoCommunication/rte/RteTsChart";
import { baseResources } from "config_infos";
import { GROUP_TYPES } from "enums";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Datagrid,
  DateField,
  Labeled,
  NumberField,
  ReferenceArrayField,
  SelectField,
  Show,
  TextField,
  useRecordContext,
} from "react-admin";
import { RteConfigType } from "types";
import { Ti2Enum, Ti2TmEnum, Ti2TsEnum } from "types/enums";

export const GroupShow = () => {
  const frGroupTypeOptions = useMemo(
    () =>
      GROUP_TYPES.map(({ name, value }) => ({
        id: value,
        name,
      })),
    [],
  );

  return (
    <Show>
      <Box p={2}>
        <Stack direction={"row"} spacing={4}>
          <Labeled source="name">
            <TextField source="name" fullWidth />
          </Labeled>
          <Labeled source="code">
            <TextField source="code" fullWidth />
          </Labeled>
          <Labeled source="code_tm">
            <TextField source="code_tm" fullWidth />
          </Labeled>
          <Labeled source="mode">
            <TextField source="mode" fullWidth />
          </Labeled>
          <Labeled source={"type"}>
            <SelectField source={"type"} choices={frGroupTypeOptions} />
          </Labeled>
        </Stack>
        <Divider sx={{ p: 2 }} />
        <Labeled source={"Sites"} m={2}>
          <ReferenceArrayField source="site_ids" reference={baseResources.sites.SITES} label="Sites">
            <Datagrid bulkActionButtons={false} rowClick={"show"}>
              <NumberField source="id" />
              <TextField source="name" />
              <TextField source="city" />
              <DateField source="created_at" />
            </Datagrid>
          </ReferenceArrayField>
        </Labeled>
      </Box>
      <RteCommunicationGraphVisualizations />
    </Show>
  );
};

const RteCommunicationGraphVisualizations = () => {
  const group = useRecordContext();
  const [configs, setConfigs] = useState<RteConfigType[]>([]);
  const canFetch = !!group && group.type === "rte_edr";

  const getConfigs = useCallback(async () => {
    const {
      data: { items },
    } = await axios({
      method: "get",
      url: `${process.env.REACT_APP_IEC_API_URL}/${baseResources.iec.CONFIGS}`,
      params: {
        page: 1,
        size: 100,
        code: group?.code_tm || "",
      },
    });
    return items;
  }, [group]);

  useEffect(() => {
    if (canFetch) {
      getConfigs()
        .then((configs) => {
          setConfigs(configs.filter((config: RteConfigType) => config.ti_2 !== Ti2Enum.N_RSFP));
        })
        .catch((error) => {
          console.log("error:", error);
        });
    }
  }, [canFetch, getConfigs]);

  if (!canFetch || configs.length === 0) return <></>;

  const [tmIoas, tsIoas] = configs.reduce(
    (acc: [number[], { [key: string]: number }], config: RteConfigType) => {
      if (Object.values(Ti2TmEnum).includes(config.ti_2 as Ti2TmEnum)) {
        acc[0].push(config.ioa);
      }
      if (Object.values(Ti2TsEnum).includes(config.ti_2 as Ti2TsEnum)) {
        acc[1][config.ti_2] = config.ioa;
      }
      return acc;
    },
    [[], {}],
  );

  return (
    <Card sx={{ p: 4 }}>
      <RteTmChart ioas={tmIoas} />
      <RteTsChart ioas={tsIoas} />
    </Card>
  );
};
