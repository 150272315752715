import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Card, CardContent } from "@mui/material";
import CustomBooleanField from "components/commons/fields/CustomBooleanField";
import DurationField from "components/commons/fields/DurationField";
import { CustomDateFilter } from "components/commons/inputs/CustomDateFilter";
import { baseResources } from "config_infos";
import { endOfWeek, subWeeks } from "date-fns";
import { useMemo } from "react";
import {
  BooleanInput,
  Datagrid,
  DateField,
  DateTimeInput,
  EditButton,
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  List,
  ReferenceField,
  ShowButton,
  TextField,
} from "react-admin";

const StatusesMultipleFilterSidebar = () => {
  const now = Date.now();
  return (
    <Card sx={{ order: -1, mr: 2, mt: 9, width: 230 }}>
      <CardContent>
        <FilterLiveSearch source="search" />
        <CustomDateFilter label="Duration over" source={"duration__gt"} timeOnly={true} />
        <CustomDateFilter label="Duration under" source={"duration__lt"} timeOnly={true} />
        <FilterList label="Filters" icon={<FilterAltIcon />}>
          <FilterListItem
            label="Last week"
            value={{
              timestamp__lt: subWeeks(endOfWeek(now, { weekStartsOn: 1 }), 1),
              timestamp__gt: subWeeks(endOfWeek(now, { weekStartsOn: 1 }), 2),
            }}
          />
        </FilterList>
      </CardContent>
    </Card>
  );
};

export const StatusesList = (props: any) => {
  const { isSite, ...rest } = props;

  const statusesFilters = useMemo(
    () => [
      <DateTimeInput key="timestamp__gt" label="After" source="timestamp__gt" alwaysOn />,
      <DateTimeInput key="timestamp__lt" label="Before" source="timestamp__lt" />,
      <BooleanInput key="network" label="Network" source="network" defaultValue={false} />,
      <BooleanInput key="control" label="Control" source="control" defaultValue={false} />,
      <BooleanInput key="measure" label="Measure" source="measure" defaultValue={false} />,
      <BooleanInput key="sequence" label="Sequence" source="sequence" defaultValue={false} />,
      <BooleanInput key="power_clamping" label="Power clamping" source="power_clamping" defaultValue={false} />,
      <BooleanInput
        key="aggregator_module"
        label="Aggregator module"
        source="aggregator_module"
        defaultValue={false}
      />,
    ],
    [],
  );

  const allStatusesProps = {
    filters: statusesFilters,
    aside: <StatusesMultipleFilterSidebar />,
  };

  const listProps2 = {
    ...rest,
    ...(isSite ? {} : allStatusesProps),
  };

  return (
    <List {...listProps2} sort={{ field: "timestamp", order: "DESC" }} title={isSite ? " " : "Statuses"}>
      <Datagrid bulkActionButtons={false}>
        {/* No choice but to repeat https://marmelab.com/react-admin/Permissions.html#restricting-access-to-fields-and-inputs */}
        {!isSite && <TextField source="id" />}
        {!isSite && (
          <ReferenceField source="site_id" reference={baseResources.sites.SITES} link={"show"} sortable={false} />
        )}
        <DateField showTime source="timestamp" />
        <DateField showTime source="end_date" />
        <CustomBooleanField source="network" />
        <CustomBooleanField source="control" />
        <CustomBooleanField source="measure" />
        <CustomBooleanField source="sequence" />
        <CustomBooleanField source="power_clamping" />
        <CustomBooleanField source="aggregator_module" />
        <DurationField source="duration" />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
