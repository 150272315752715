export enum ecpBidBusinessTypeEnum {
  FCR_N = "fcr_n",
  FCR_D = "fcr_d",
}

export enum EcpBidStatusEnum {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  PARTIALLY_ACCEPTED = "PARTIALLY_ACCEPTED",
  REFUSED = "REFUSED",
}

export enum EcpBidDirectionEnum {
  UPWARD = "UPWARD",
  SYMMETRIC = "SYMMETRIC",
  DOWNWARD = "DOWNWARD",
}

export type EcpBidType = {
  start_date: string | Date;
  end_date: string | Date;
  offered_quantity_mw: number;
  accepted_quantity_mw: number;
  offered_price_eur: number;
  accepted_price_eur: number;
  direction: EcpBidDirectionEnum;
  business_type: ecpBidBusinessTypeEnum;
  status: EcpBidStatusEnum;
  gate_id: string | null;
};

export type EcpGateWithBidsType = {
  delivery_date: string | Date;
  bids: EcpBidType[];
};
