import { Box } from "@mui/material";
import { validatePlanningTime } from "components/validations";
import { PLANNING_MODE } from "enums";
import { BooleanInput, DateTimeInput, Edit, NumberInput, required, SelectInput, SimpleForm } from "react-admin";

export default function PlanningEdit() {
  const modeOptions = PLANNING_MODE.map((mode) => ({ id: mode, name: mode }));
  return (
    <Edit>
      <SimpleForm mode="onBlur">
        <Box
          width={"100%"}
          pl={2}
          pr={2}
          display={"grid"}
          gridTemplateColumns={"repeat(3, 33%)"}
          columnGap={2}
          alignItems="center"
          justifyContent="center"
        >
          <DateTimeInput source="start_date" validate={[required(), validatePlanningTime]} />
          <DateTimeInput source="end_date" validate={[required(), validatePlanningTime]} />
          <SelectInput source="mode" choices={modeOptions} validate={required()} />
          <NumberInput source="fcr_engagement" />
          <NumberInput source="target_soc" />
          <NumberInput source="target_power_mw" />
          <NumberInput source="initial_soc_restoration_power" />
          <NumberInput source="soc_restoration_power" />
          <NumberInput source="reactivity_delay" />
          <BooleanInput source="overidable" sx={{ m: "auto" }} />
        </Box>
      </SimpleForm>
    </Edit>
  );
}
