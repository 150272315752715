import {
  Datagrid,
  DateField,
  Labeled,
  NumberField,
  ReferenceArrayField,
  SelectField,
  Show,
  ShowActions,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import { useParams } from "react-router-dom";

import { Box, Grid, Paper, Typography } from "@mui/material";

import { baseResources } from "config_infos";
import { subDays } from "date-fns";

import PlanningChart from "components/charts/planning/PlanningChart";
import { MaintenancesList } from "components/entities/maintenances/MaintenancesList";
import { StatusesList } from "components/entities/site_service/sites_statuses/StatusesList";
import { SITE_MODES } from "enums";
import "react-big-scheduler-stch/lib/css/style.css";
import SiteLocation from "./SiteLocation";
import TelemetryChart from "components/charts/TelemetryChart";

type LabeledFieldProps = {
  source: string;
  label?: string;
  ComponentField?: React.ComponentType<any>;
};

const LabeledField = ({ source, label, ComponentField = TextField }: LabeledFieldProps) => (
  <Labeled source={source} label={label || source}>
    <ComponentField source={source} fullWidth />
  </Labeled>
);

export const SiteShow = () => {
  const { id } = useParams();

  return (
    <Show actions={<ShowActions />}>
      <SimpleShowLayout>
        <div>
          <Box display={"grid"} gridTemplateColumns={{ xs: "1fr", md: "1fr 1fr" }}>
            <Box>
              <Paper elevation={4} sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  {"Site Infos"}
                </Typography>
                <Box display={"grid"} gridTemplateColumns={{ xs: "1fr", sm: "1fr 1fr", md: "1fr 1fr 1fr" }} gap={2}>
                  <LabeledField source="name" />
                  <LabeledField source="dso" />
                  <LabeledField source="rte_code" label={"Tranche TM RTE"} />
                  <LabeledField source="email" />
                  <LabeledField source="city" />
                  <LabeledField source="zipcode" />
                  <LabeledField source="country" />
                  <Labeled source="mode">
                    <SelectField source={"mode"} choices={SITE_MODES} />
                  </Labeled>
                  <LabeledField source="soc_reserve_inf" ComponentField={NumberField} />
                  <LabeledField source="soc_inf" ComponentField={NumberField} />
                  <LabeledField source="soc_sup" ComponentField={NumberField} />
                  <LabeledField source="soc_reserve_up" ComponentField={NumberField} />
                  <LabeledField source="useful_energy_at_1mw" ComponentField={NumberField} />
                </Box>
              </Paper>
              <Paper elevation={4} sx={{ p: 2, mt: 2 }}>
                <Typography variant="h6" gutterBottom>
                  {"Group Infos"}
                </Typography>
                <ReferenceArrayField source="group_ids" reference={baseResources.sites.GROUPS}>
                  <Datagrid bulkActionButtons={false}>
                    <TextField source="name" />
                    <TextField source="code" />
                    <DateField source="created_at" />
                  </Datagrid>
                </ReferenceArrayField>
              </Paper>
            </Box>
            <Paper elevation={4} sx={{ ml: 2 }}>
              <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <SiteLocation />
              </Box>
            </Paper>
          </Box>

          <Grid container width={{ xs: "100%", md: "80vw" }} mt={"2px"} spacing={2}>
            <Grid item xs={12} md={12}>
              <TelemetryChart siteId={id} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Paper elevation={4} sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Plannings and Masks
                </Typography>
                <PlanningChart siteId={id} />
              </Paper>
            </Grid>

            <Grid item xs={12} md={12}>
              <Paper elevation={4} sx={{ p: 2, mt: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Maintenances
                </Typography>
                <MaintenancesList
                  resource={baseResources.maintenance.MAINTENANCE}
                  filter={{ site_id: id, end__gt: subDays(new Date(), 7).toISOString() }}
                  disableSynWithLocation
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={12}>
              <Paper elevation={4} sx={{ p: 2, mt: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Statuses
                </Typography>
                <StatusesList
                  resource={baseResources.sites.STATUSES}
                  filter={{ site_id: id }}
                  isSite={true}
                  disableSyncWithLocation
                />
              </Paper>
            </Grid>
          </Grid>
        </div>
      </SimpleShowLayout>
    </Show>
  );
};
