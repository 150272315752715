import axios from "axios";
import { baseResources } from "config_infos";
import { addHours, setHours } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { useCallback } from "react";
import {
  ArrayInput,
  DateInput,
  FormDataConsumer,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  useNotify,
  useRedirect,
} from "react-admin";
import { FieldValues, SubmitHandler } from "react-hook-form";
import { EcpGateWithBidsType } from "../types";
import { ecpBidBusinessTypeChoices, ecpBidDirectionChoices } from "../utils";
import { validateBids } from "./ecpBaseFormValidation";

type GateEditProps = {
  isEdit: boolean;
  defaultValues: EcpGateWithBidsType | null;
};

const EcpGateBaseForm = ({ isEdit, defaultValues }: GateEditProps) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSubmit: SubmitHandler<FieldValues> = useCallback(
    async (values) => {
      const { bids, delivery_date } = values;
      const formattedBids: any[] = [];

      bids.forEach((bid: any) => {
        const { start_hour, end_hour, ...rest } = bid;

        // Convert delivery date to Finland time (Europe/Helsinki)
        const deliveryDateInFinland = zonedTimeToUtc(new Date(delivery_date), "Europe/Helsinki");

        // Divide the bid into 1-hour intervals
        for (let hour = start_hour; hour < end_hour; hour++) {
          const start_date = setHours(deliveryDateInFinland, hour);
          const end_date = addHours(start_date, 1);
          formattedBids.push({
            ...rest,
            start_date: start_date.toISOString(),
            end_date: end_date.toISOString(),
          });
        }
      });

      const data = { delivery_date, bids: formattedBids };
      console.log("data:", data);

      const url = `${process.env.REACT_APP_ECP_API_URL}/${baseResources.ecp.BIDS}/many`;

      try {
        const { status } = await axios({
          method: "post",
          url,
          data,
        });
        if (status >= 200 && status < 300) {
          notify("Bids created successfully", { type: "success" });
          redirect("list", baseResources.ecp.GATES);
        }
      } catch (error: any) {
        notify(`Error: ${error.message}`, { type: "error" });
      }
    },
    [notify, redirect],
  );

  return (
    <SimpleForm
      defaultValues={defaultValues}
      validate={validateBids}
      onSubmit={handleSubmit}
      sanitizeEmptyValues
      mode="onBlur"
    >
      <DateInput source="delivery_date" validate={required()} disabled={isEdit} />
      <ArrayInput source="bids">
        <SimpleFormIterator
          inline
          sx={{
            "& .RaSimpleFormIterator-form": {
              display: "grid",
              gridTemplateColumns: {
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
                md: "repeat(3, 1fr)",
              },
              gap: 2,
              p: 2,
              pb: 0,
              borderRadius: 2,
              boxShadow: 4,
              mt: 2,
              ml: 2,
            },
            "& .RaSimpleFormIterator-line": {
              border: "none",
            },
          }}
        >
          <SelectInput source="business_type" choices={ecpBidBusinessTypeChoices} validate={required()} />
          <FormDataConsumer>
            {({ scopedFormData }) => {
              const disabled = !scopedFormData || !scopedFormData.business_type;
              return (
                <>
                  <NumberInput
                    source="start_hour"
                    min={0}
                    max={23}
                    helperText="Finnish time"
                    validate={required()}
                    disabled={disabled}
                  />
                  <NumberInput
                    source="end_hour"
                    min={1}
                    max={24}
                    helperText="Finnish time, must be greater than start hour"
                    validate={required()}
                    disabled={disabled}
                  />
                  <NumberInput
                    source="offered_quantity_mw"
                    label="Offered Quantity (MW)"
                    step={0.1}
                    min={0}
                    validate={required()}
                    disabled={disabled}
                  />
                  <NumberInput
                    source="offered_price_eur"
                    label="Offered Price (EUR/MW)"
                    step={0.01}
                    min={0}
                    validate={required()}
                    disabled={disabled}
                  />
                  <SelectInput
                    source="direction"
                    choices={ecpBidDirectionChoices}
                    validate={required()}
                    disabled={disabled}
                  />
                </>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
};

export default EcpGateBaseForm;
