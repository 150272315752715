import { FieldValues } from "react-hook-form";

// Function to check if two time periods overlap
const isOverlap = (start1: number, end1: number, start2: number, end2: number) => {
  return start1 < end2 && start2 < end1;
};

// Global form validation function
export const validateBids = (values: FieldValues) => {
  const errors: Record<string, any> = {};

  // Validate delivery date
  if (!values.delivery_date) {
    errors.delivery_date = "The delivery date is required";
  }

  // Validate bids array
  if (!values.bids || !values.bids.length) {
    errors.bids = "At least one bid is required";
  } else {
    const startHourSet = new Set();
    const bidGroups: Record<string, { start_hour: number; end_hour: number }[]> = {}; // Group bids by business_type
    errors.bids = values.bids.map((bid: any) => {
      const bidErrors: Record<string, any> = {};

      // Validate start_hour: must be an integer between 0 and 23
      if (bid.start_hour == null) {
        bidErrors.start_hour = "Start hour is required";
      } else if (!Number.isInteger(bid.start_hour)) {
        bidErrors.start_hour = "Start hour must be an integer";
      } else if (bid.start_hour < 0 || bid.start_hour > 23) {
        bidErrors.start_hour = "Start hour must be between 0 and 23";
      }

      // Validate end_hour: must be an integer between 1 and 24 and greater than start_hour
      if (bid.end_hour == null) {
        bidErrors.end_hour = "End hour is required";
      } else if (!Number.isInteger(bid.end_hour)) {
        bidErrors.end_hour = "End hour must be an integer";
      } else if (bid.end_hour < 1 || bid.end_hour > 24) {
        bidErrors.end_hour = "End hour must be between 1 and 24";
      } else if (bid.end_hour <= bid.start_hour) {
        bidErrors.end_hour = "End hour must be greater than start hour";
        bidErrors.start_hour = "End hour must be greater than start hour";
      }

      // Ensure start_hour is unique
      if (startHourSet.has(bid.start_hour)) {
        bidErrors.start_hour = "Start hour must be unique";
      }
      startHourSet.add(bid.start_hour);

      // Validate offered_quantity_mw based on business_type
      const businessType = bid.business_type;
      const minQuantity = businessType === "fcr_n" ? 0.1 : businessType === "fcr_d" ? 1 : 0;
      if (bid.offered_quantity_mw == null || bid.offered_quantity_mw < minQuantity) {
        bidErrors.offered_quantity_mw = `Offered quantity must be at least ${minQuantity} for ${businessType}`;
      }

      // Validate offered_price_eur: must be >= 0 and have step of 0.01
      if (bid.offered_price_eur == null || bid.offered_price_eur < 0) {
        bidErrors.offered_price_eur = "Offered price must be greater than or equal to 0";
      }

      if (!bid.direction) {
        bidErrors.direction = "Direction is required";
      }

      if (!bid.business_type) {
        bidErrors.business_type = "Business type is required";
      }

      // Check for overlapping bids with the same business_type
      if (!bidErrors.start_hour && !bidErrors.end_hour) {
        const currentBid = { start_hour: bid.start_hour, end_hour: bid.end_hour };

        if (!bidGroups[businessType]) {
          bidGroups[businessType] = [];
        } else {
          const overlappingBid = bidGroups[businessType].find((existingBid) =>
            isOverlap(currentBid.start_hour, currentBid.end_hour, existingBid.start_hour, existingBid.end_hour),
          );

          if (overlappingBid) {
            bidErrors.start_hour = `Bids with the same business type (${businessType}) cannot overlap`;
            bidErrors.end_hour = `Bids with the same business type (${businessType}) cannot overlap`;
          }
        }

        // Add the current bid to the group for future overlap checks
        bidGroups[businessType].push(currentBid);
      }

      return bidErrors;
    });
  }

  return errors;
};
