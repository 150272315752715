import { baseResources } from "config_infos";
import { enumToChoices } from "helpers/reactAdminHelpers";
import { useMemo } from "react";
import { AutocompleteInput, Create, ReferenceInput, required, SelectInput, SimpleForm, TextInput } from "react-admin";
import GroupCodeAutocompleteInput from "../GroupCodeAutocompleteInput";
import { OptimizerCertificationStatusEnum, OptimizerGroupTypeEnum } from "../opti_enums";

export default function GroupsCertificationsCreate() {
  const statusOptions = useMemo(() => enumToChoices(OptimizerCertificationStatusEnum), []);

  return (
    <Create>
      <SimpleForm>
        <TextInput source="name" label="Group Certification Name" validate={[required()]} fullWidth />
        <SelectInput source="status" choices={statusOptions} validate={[required()]} fullWidth />
        <GroupCodeAutocompleteInput
          filter={{
            type: OptimizerGroupTypeEnum.RTE_EDP,
          }}
          validate={[required()]}
        />
        <ReferenceInput source="certification_id" reference={baseResources.optimizer.CERTIFICATIONS}>
          <AutocompleteInput
            optionText={({ id, certif_name }: any) => {
              return `${id} - ${certif_name}`;
            }}
            validate={[required()]}
            fullWidth
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}
