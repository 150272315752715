import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import ExplicitValuesBoolenInput from "components/commons/inputs/ExplicitValuesBooleanInput";
import { baseResources } from "config_infos";
import { RTE_FCR_COMPUTATION_VERSION, SITE_COUNTRIES, SITE_DSO, SITE_MODES, SITE_OPERATOR, SITE_SUPPLIER } from "enums";
import { getDataproviderCustomErrorMessage } from "errors/handlers";
import React, { useCallback } from "react";
import {
  AutocompleteArrayInput,
  DateTimeInput,
  NumberInput,
  ReferenceArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  maxValue,
  minValue,
  required,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRedirect,
} from "react-admin";
import { siteValidations, socValidations } from "./validations";

type SitesBaseFormProps = {
  isCreateForm?: boolean;
};

type InputContainerProps = {
  source: string;
  InputType?: React.ComponentType<any>;
  gridItemProps?: any;
  inputProps?: any;
};

const InputContainer = ({ source, InputType = TextInput, gridItemProps, inputProps }: InputContainerProps) => {
  return (
    <Grid item xs={4} {...gridItemProps}>
      <InputType fullWidth source={source} {...inputProps} />
    </Grid>
  );
};

export default function SitesBaseForm({ isCreateForm = true }: SitesBaseFormProps) {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const record = useRecordContext();
  const socRelatedValidations = useCallback((value: number, allValues: any, inputInfos: any) => {
    return socValidations(value, allValues, inputInfos);
  }, []);

  const handleSubmit = async (data: any) => {
    try {
      if (isCreateForm) {
        await dataProvider.create(baseResources.sites.SITES, { data });
        notify("Site created successfully", { type: "success" });
      } else {
        await dataProvider.update(baseResources.sites.SITES, { id: data.id, data, previousData: record });
        notify("Site updated successfully", { type: "success" });
      }
      redirect("list", baseResources.sites.SITES);
    } catch (error: any) {
      notify(getDataproviderCustomErrorMessage(error), { type: "error" });
    }
  };

  return (
    <SimpleForm mode="onBlur" validate={siteValidations} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Basic Information" />
            <CardContent>
              <Grid container spacing={2}>
                {isCreateForm && (
                  <InputContainer InputType={NumberInput} source="id" inputProps={{ validate: [required()] }} />
                )}
                <InputContainer source="name" inputProps={{ validate: [required()] }} />
                <InputContainer InputType={SelectInput} source="dso" inputProps={{ choices: SITE_DSO }} />
                <InputContainer source="prm" />
                <InputContainer InputType={SelectInput} source="supplier" inputProps={{ choices: SITE_SUPPLIER }} />
                <InputContainer
                  InputType={SelectInput}
                  source="site_operator"
                  inputProps={{ choices: SITE_OPERATOR }}
                />
                <Grid item xs={4}>
                  <ExplicitValuesBoolenInput label="IECharge" falseLabel="No" trueLabel="Yes" source="iecharge" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader title="Aggregation Details" />
            <CardContent>
              <Grid container spacing={2}>
                <InputContainer InputType={DateTimeInput} source="valorization_start_date" />
                <InputContainer
                  source="rte_code"
                  inputProps={{
                    label: "Tranche TM RTE",
                  }}
                />
                <InputContainer InputType={SelectInput} source="mode" inputProps={{ choices: SITE_MODES }} />
                <InputContainer
                  InputType={ReferenceArrayInput}
                  source="group_ids"
                  inputProps={{
                    reference: baseResources.sites.GROUPS,
                    child: <AutocompleteArrayInput optionText="name" />,
                  }}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader title="Location Information" />
            <CardContent>
              <Grid container spacing={2}>
                <InputContainer
                  InputType={SelectInput}
                  source="country"
                  inputProps={{ choices: SITE_COUNTRIES, validate: [required()] }}
                />
                <InputContainer source="city" />
                <InputContainer InputType={NumberInput} source="zipcode" />
                <InputContainer
                  InputType={NumberInput}
                  source="latitude"
                  inputProps={{ validate: [minValue(-90), maxValue(90)] }}
                />
                <InputContainer
                  InputType={NumberInput}
                  source="longitude"
                  inputProps={{ validate: [minValue(-180), maxValue(180)] }}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader title="Network Information" />
            <CardContent>
              <Grid container spacing={2}>
                <InputContainer source="public_ip" />
                <InputContainer source="network_ip" />
                <InputContainer source="aggregator_module_healthcheck_url" />
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader title="Capacity and Efficiency" />
            <CardContent>
              <Grid container spacing={2}>
                <InputContainer InputType={NumberInput} source="max_cycles" />
                <InputContainer InputType={NumberInput} source="rack_count" />
                <InputContainer InputType={NumberInput} source="min_power_mw" />
                <InputContainer InputType={NumberInput} source="max_power_mw" />
                <InputContainer InputType={NumberInput} source="efficiency" />
                <InputContainer InputType={NumberInput} source="max_soc_mwh" />
                <InputContainer
                  InputType={NumberInput}
                  source="soh"
                  inputProps={{ validate: [minValue(0), maxValue(100)] }}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader
              title="Advanced Settings"
              subheader="Warning, this will automatically update the aggregator module if IP adress is filled. Note that the soc_inf/sup and soc_res_inf/sup are only required for FCR certification pre 2024."
              subheaderTypographyProps={{ color: "error" }}
            />
            <CardContent>
              <Grid container spacing={2}>
                <InputContainer
                  InputType={SelectInput}
                  source="rte_fcr_computation_version"
                  inputProps={{ choices: RTE_FCR_COMPUTATION_VERSION, defaultValue: 2 }}
                />
                <InputContainer InputType={NumberInput} source="useful_energy_at_1mw" />
                <InputContainer
                  InputType={NumberInput}
                  source="soc_reserve_inf"
                  inputProps={{ validate: [socRelatedValidations] }}
                />
                <InputContainer
                  InputType={NumberInput}
                  source="soc_reserve_sup"
                  inputProps={{ validate: [socRelatedValidations] }}
                />
                <InputContainer
                  InputType={NumberInput}
                  source="soc_inf"
                  inputProps={{ validate: [socRelatedValidations] }}
                />
                <InputContainer
                  InputType={NumberInput}
                  source="soc_sup"
                  inputProps={{ validate: [socRelatedValidations] }}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </SimpleForm>
  );
}
