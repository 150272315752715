import { baseResources } from "config_infos";
import { AutocompleteInput, ReferenceInput } from "react-admin";

export default function GroupCodeAutocompleteInput({ fullWidth = true, ...props }: any) {
  return (
    <ReferenceInput source="group_code" reference={baseResources.sites.GROUPS} fullWidth={fullWidth}>
      <AutocompleteInput
        optionText={({ id, name, code }: any) => {
          return `${id} - ${name} - ${code}`;
        }}
        fullWidth={fullWidth}
        optionValue="code"
        {...props}
      />
    </ReferenceInput>
  );
}
