import { Grid } from "@mui/material";
import { DateTimeInput, Edit, NumberInput, SelectInput, SimpleForm, TextInput } from "react-admin";
import GroupCodeAutocompleteInput from "../GroupCodeAutocompleteInput";
import { optimizerCertificationsTestsTypeChoices } from "../opti_enums";

export default function CertificationsTestsEdit() {
  return (
    <Edit>
      <SimpleForm>
        <Grid container>
          <Grid item xs={4}>
            <DateTimeInput source="start_date" />
          </Grid>
          <Grid item xs={4}>
            <DateTimeInput source="end_date" />
          </Grid>
          <Grid item xs={4}>
            <TextInput source="fcr_engagement" />
          </Grid>
          <Grid item xs={4}>
            <TextInput source="afrr_engagement" />
          </Grid>
          <Grid item xs={4}>
            <GroupCodeAutocompleteInput fullWidth={false} />
          </Grid>
          <Grid item xs={4}>
            <NumberInput source="certif_id" />
          </Grid>
          <Grid item xs={4}>
            <SelectInput source="test_type" choices={optimizerCertificationsTestsTypeChoices} />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
}
