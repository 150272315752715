import { addHours, formatISO } from "date-fns";
import { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";

import { baseResources } from "config_infos";
import { subHours } from "date-fns";
import useStatusAware from "hooks/useStatusAware";
import { InitialDatasets, MaskType, PlanningOrMaskType, PlanningType } from "../../../../../types";
import useDateRangePicker from "components/commons/pickers/DateRangePicker/hooks/useDateRangePicker";

export function usePlanningsData(siteId: number) {
  const dataProvider = useDataProvider();
  const { error, loading, setError, setLoading } = useStatusAware();
  const [datasets, setDatasets] = useState<InitialDatasets>({
    plannings: [],
    masks: [],
  });

  const { dateRange, setDateRange } = useDateRangePicker(subHours(new Date(), 24), addHours(new Date(), 24));

  const fetchAllRessourcesWithinTimeRange = async (
    resourceUrl: string,
    resourceSiteId: number,
  ): Promise<PlanningOrMaskType[]> => {
    let itemsTotal = 0;
    let itemsCount = 0;
    let currentPage = 1;
    let result: PlanningOrMaskType[] = [];

    do {
      await dataProvider
        .getList(resourceUrl, {
          sort: { field: "start_date", order: "ASC" },
          pagination: { page: currentPage, perPage: 100 },
          filter: {
            site_id: resourceSiteId,
            end_date__gt: formatISO(dateRange.startDate),
            start_date__lt: formatISO(dateRange.endDate),
          },
        })
        .then(({ data, total }) => {
          itemsCount += data.length;
          itemsTotal = total ?? 0;
          currentPage++;
          result = result.concat(data);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } while (itemsCount < itemsTotal);
    return result;
  };

  const consolidatePlannings = (plannings: PlanningType[]): PlanningType[] => {
    // Use `reduce` to iterate over plannings and accumulate results in a single array
    return plannings.reduce((acc: PlanningType[], planning: PlanningType) => {
      const { start_date, end_date, mode } = planning;
      const cleanPlanning = {
        start_date,
        end_date,
        mode,
      };
      if (acc.length === 0) {
        // If the accumulator is empty, just add the first planning
        acc.push(cleanPlanning);
      } else {
        const lastPlanning = acc[acc.length - 1];
        if (lastPlanning.mode === planning.mode) {
          // If the current planning matches the last one in the accumulator in mode,
          // extend the last planning's end_date to the current planning's end_date
          lastPlanning.end_date = planning.end_date;
        } else {
          // Otherwise, push the current planning onto the accumulator
          acc.push(cleanPlanning);
        }
      }
      return acc; // Return the updated accumulator
    }, []); // Initial value of the accumulator is an empty array
  };

  const fetchResources = async () => {
    const [plannings, masks] = await Promise.all([
      fetchAllRessourcesWithinTimeRange(baseResources.plannings.PLANNINGS, siteId),
      fetchAllRessourcesWithinTimeRange(baseResources.plannings.PLANNING_MASKS, siteId),
    ]);

    setDatasets({
      plannings: consolidatePlannings(plannings as PlanningType[]),
      masks: masks as MaskType[],
    });

    setLoading(false);
  };
  useEffect(() => {
    fetchResources();
  }, [siteId]); // eslint-disable-line

  const data = {
    ...datasets,
    dateRange,
  };

  return { data, error, loading, setDateRange };
}
