import { ChipField, Datagrid, List, ReferenceArrayField, SingleFieldList, TextField } from "react-admin";
import { baseResources } from "config_infos";

export const GroupList = () => (
  <List>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="mode" sortable={false} />
      <TextField source="code" sortable={false} />
      <TextField source="code_tm" sortable={false} />
      <TextField source="type" sortable={false} />
      <ReferenceArrayField source="site_ids" reference={baseResources.sites.SITES} sortable={false} label={"Sites"}>
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </Datagrid>
  </List>
);
