import { Box } from "@mui/material";

type ColorTagProps = {
  color: string;
  width?: number;
  height?: number;
  border?: string;
  borderRadius?: string;
};

const ColorTag = ({
  color,
  width = 20,
  height = 20,
  border = "1px solid lightgrey",
  borderRadius = "50%",
}: ColorTagProps) => {
  return color ? (
    <Box
      sx={{
        width,
        height,
        bgcolor: color,
        border,
        borderRadius,
      }}
    />
  ) : null;
};

export default ColorTag;
