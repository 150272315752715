import { CHRONICLE_FILENAMES_ENUM, PLANNING_MODE_ENUM } from "enums";

export const PLANNING_ROLES = {
  socTrial1: "socTrial1",
  socTrial2: "socTrial2",
  socTrial3: "socTrial3",
  pProgTrial1: "pProgTrial1",
  pProgTrial2: "pProgTrial2",
  pProgTrial3: "pProgTrial3",
  pProgTrial4: "pProgTrial4",
};

export const PLANNING_TYPE = {
  AFRR: "AFRR",
  SOC_MANAGEMENT: "SOC_MANAGEMENT",
};

export const baseAfrrValues = [
  {
    name: "SOC Management 1",
    mode: PLANNING_MODE_ENUM.soc_management,
    chronicle_filename: null,
    duration: { hours: 1 },
    role: PLANNING_ROLES.socTrial1,
    type: PLANNING_TYPE.SOC_MANAGEMENT,
    testsParticipation: "test1",
  },
  {
    name: "AFRR 1",
    mode: PLANNING_MODE_ENUM.afrr_rte,
    chronicle_filename: CHRONICLE_FILENAMES_ENUM.r2_essai_1_file1,
    duration: { minutes: 15 },
    role: PLANNING_ROLES.pProgTrial1,
    type: PLANNING_TYPE.AFRR,
    testsParticipation: "test1",
  },
  {
    name: "AFRR 1.1",
    mode: PLANNING_MODE_ENUM.afrr_rte,
    chronicle_filename: CHRONICLE_FILENAMES_ENUM.r2_essai_1_file2,
    duration: { minutes: 20 },
    role: PLANNING_ROLES.pProgTrial2,
    type: PLANNING_TYPE.AFRR,
    testsParticipation: "test1",
  },
  {
    name: "SOC Management 2",
    mode: PLANNING_MODE_ENUM.soc_management,
    chronicle_filename: null,
    duration: { minutes: 55 },
    role: PLANNING_ROLES.socTrial2,
    type: PLANNING_TYPE.SOC_MANAGEMENT,
    testsParticipation: "test2",
  },
  {
    name: "AFRR 2",
    mode: PLANNING_MODE_ENUM.afrr_rte,
    chronicle_filename: CHRONICLE_FILENAMES_ENUM.r2_essai_2_file1,
    duration: { minutes: 15 },
    role: PLANNING_ROLES.pProgTrial3,
    type: PLANNING_TYPE.AFRR,
    testsParticipation: "test2",
  },
  {
    name: "AFRR 2.1",
    mode: PLANNING_MODE_ENUM.afrr_rte,
    chronicle_filename: CHRONICLE_FILENAMES_ENUM.r2_essai_2_file2,
    duration: { minutes: 20 },
    role: PLANNING_ROLES.pProgTrial4,
    type: PLANNING_TYPE.AFRR,
    testsParticipation: "test2",
  },
  {
    name: "SOC Management 3",
    mode: PLANNING_MODE_ENUM.soc_management,
    chronicle_filename: null,
    duration: { minutes: 55 },
    role: PLANNING_ROLES.socTrial3,
    type: PLANNING_TYPE.SOC_MANAGEMENT,
    testsParticipation: "all",
  },
];
