import { useEffect, useState } from "react";
import { Loading, useDataProvider } from "react-admin";

import type { ChartData, ChartOptions } from "chart.js";

import { Alert, AlertTitle, Box } from "@mui/material";
import { Chart as ChartJS, registerables } from "chart.js";
import "chartjs-adapter-date-fns";
import zoomPlugin from "chartjs-plugin-zoom";
import { baseResources } from "config_infos";
import { parseISO, subHours } from "date-fns";
import useStatusAware from "hooks/useStatusAware";
import { Line } from "react-chartjs-2";

ChartJS.register(...registerables, zoomPlugin);

const TelemetryChart = ({ siteId }: { siteId?: number | string }) => {
  const dataProvider = useDataProvider();
  const { error, loading, setError, setLoading } = useStatusAware();
  const [datasets, setDatasets] = useState<{
    power: any[];
    frequency: any[];
    soc: any[];
    nLevel: any[];
  }>({
    power: [],
    frequency: [],
    soc: [],
    nLevel: [],
  });
  const [labels, setLabels] = useState<string[]>([]);

  const chartColors = {
    frequency: "#006ebc",
    soc: "#00ac50",
    power: "#c50206",
    nLevel: "#e69138",
  };

  const chartOptions: ChartOptions<"line"> = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Telemetry",
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "x",
        },
      },
      tooltip: {
        mode: "index",
        intersect: false,
      },
    },
    scales: {
      x: {
        ticks: {
          callback: (val: any, index: number): string => {
            // Hide every 2nd tick label
            return index % 50 === 0 ? parseISO(labels[val])?.toLocaleString() : "";
          },
        },
      },

      y_freq: {
        type: "linear",
        suggestedMin: 49.9,
        suggestedMax: 50.1,
        display: true,
        position: "left",
        title: {
          display: true,
          color: chartColors.frequency,
          text: "frequency",
        },
        ticks: {
          color: chartColors.frequency,
        },
      },
      y_power: {
        type: "linear",
        suggestedMin: -10,
        suggestedMax: 10,
        display: true,
        position: "left",
        title: {
          display: true,
          color: chartColors.power,
          text: "P active",
        },
        ticks: {
          color: chartColors.power,
        },
      },
      y_soc: {
        type: "linear",
        suggestedMin: 45,
        suggestedMax: 55,
        display: true,
        position: "left",
        title: {
          display: true,
          color: chartColors.soc,
          text: "SOC %",
        },
        ticks: {
          color: chartColors.soc,
        },
      },
      y_nLevel: {
        type: "linear",
        suggestedMin: -1,
        suggestedMax: 1,
        display: true,
        position: "right",
        title: {
          display: true,
          color: chartColors.nLevel,
          text: "N level",
        },
        ticks: {
          color: chartColors.nLevel,
        },
      },
    },
  };

  const chartData: ChartData<"line"> = {
    labels,
    datasets: [
      {
        label: "frequency",
        pointRadius: 0,
        borderColor: chartColors.frequency,
        backgroundColor: chartColors.frequency,
        yAxisID: "y_freq",
        data: datasets.frequency,
      },
      {
        label: "active power",
        pointRadius: 0,
        borderColor: chartColors.power,
        backgroundColor: chartColors.power,
        data: datasets.power,

        yAxisID: "y_power",
      },
      {
        label: "soc",
        pointRadius: 0,
        borderColor: chartColors.soc,
        backgroundColor: chartColors.soc,
        data: datasets.soc,
        yAxisID: "y_soc",
      },
      {
        label: "N Level",
        pointRadius: 0,
        borderColor: chartColors.nLevel,
        backgroundColor: chartColors.nLevel,
        data: datasets.nLevel,
        yAxisID: "y_nLevel",
      },
    ],
  };

  const fetchAllRessourcesWithinTimeRange = async (resourceUrl: string, resourceSiteId: number) => {
    let itemsTotal = 0;
    let itemsCount = 0;
    let currentPage = 1;
    let result: any[] = [];

    do {
      await dataProvider
        .getList(resourceUrl, {
          sort: { field: "timestamp", order: "ASC" },
          pagination: { page: currentPage, perPage: 1000 },
          filter: {
            site_id: resourceSiteId,
            timestamp__lte: new Date().toISOString(),
            timestamp__gte: subHours(new Date(), 1).toISOString(),
          },
        })
        .then(({ data, total }) => {
          itemsCount += data.length;
          itemsTotal = total ?? 0;
          currentPage++;
          result = result.concat(data);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } while (itemsCount < itemsTotal);
    return result;
  };

  const alignDataWithTimestamps = (telemetryData: any[], nLevelData: any[]) => {
    let nLevelIndex = 0;
    let lastValidNLevelValue = nLevelData.length > 0 ? nLevelData[0].value : null;

    return telemetryData.map((telemetryItem) => {
      // Move the nLevel index to the closest timestamp that does not exceed the current telemetry timestamp
      while (
        nLevelIndex < nLevelData.length - 1 &&
        new Date(nLevelData[nLevelIndex + 1].timestamp) <= new Date(telemetryItem.timestamp)
      ) {
        nLevelIndex++;
        lastValidNLevelValue = nLevelData[nLevelIndex].value;
      }

      // If there's a perfect match or the closest preceding timestamp, use its value
      return lastValidNLevelValue;
    });
  };

  const fetchResources = async () => {
    const [telemetry, nLevels] = await Promise.all([
      fetchAllRessourcesWithinTimeRange(baseResources.sites.TELEMETRY_1S, siteId as number),
      fetchAllRessourcesWithinTimeRange(baseResources.sites.NLEVELS, siteId as number),
    ]);

    const nLevelAligned = alignDataWithTimestamps(telemetry, nLevels);

    setDatasets({
      power: telemetry.map((d) => d.active_power),
      frequency: telemetry.map((d) => d.frequency),
      soc: telemetry.map((d) => d.soc),
      nLevel: nLevelAligned,
    });

    setLabels(telemetry.map((d) => d.timestamp));
    setLoading(false);
  };

  useEffect(() => {
    fetchResources();
  }, [siteId]); // eslint-disable-line

  const CHART_HEIGHT = 400;

  if (loading)
    return (
      <Loading
        sx={{
          maxHeight: CHART_HEIGHT,
        }}
      />
    );

  if (error)
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        <>
          <strong>{error.name}</strong> - {error.message}
          {error.cause ?? ""}
          {error.stack ?? ""}
        </>
      </Alert>
    );

  return (
    <Box height={CHART_HEIGHT}>
      <Line options={chartOptions} data={chartData} />
    </Box>
  );
};

export default TelemetryChart;
