import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, IconButton } from "@mui/material";
import { Error } from "components/commons/statuses/Error";
import { validatePlanningTime } from "components/validations";
import { baseResources } from "config_infos";
import { CHRONICLE_FILENAMES, PLANNING_MODE } from "enums";
import { useCallback } from "react";
import {
  ArrayInput,
  BooleanInput,
  Create,
  DateTimeInput,
  FormDataConsumer,
  FormDataConsumerRenderParams,
  Identifier,
  Loading,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  required,
  useCreate,
  useGetOne,
} from "react-admin";
import { useNavigate } from "react-router-dom";

interface PlanningCreateProps {
  defaultValues: object | null;
}

export default function PlanningCreate({ defaultValues = null }: PlanningCreateProps) {
  const navigate = useNavigate();
  const [create, { isLoading, error }]: any = useCreate();

  const handleSubmit = useCallback(
    (values: any) => {
      const data = values.sites.reduce((acc: any, currentSite: any) => {
        const { site_id, plannings } = currentSite;
        return {
          ...acc,
          [site_id]: plannings.map((planning: any) => ({
            ...planning,
            site_id: site_id,
            fcr_engagement: planning?.fcr_engagement !== "" ? planning?.fcr_engagement : null,
            initial_soc_restoration_power:
              planning?.initial_soc_restoration_power !== "" ? planning?.initial_soc_restoration_power : null,
            soc_restoration_power: planning?.soc_restoration_power !== "" ? planning?.soc_restoration_power : null,
            reactivity_delay: planning?.reactivity_delay !== "" ? planning?.reactivity_delay : null,
            target_power_mw: planning?.target_power_mw !== "" ? planning?.target_power_mw : null,
            target_soc: planning?.target_soc !== "" ? planning?.target_soc : null,
          })),
        };
      }, {});

      create(
        baseResources.plannings.PLANNINGS,
        { data },
        {
          onSuccess: () => navigate(`/${baseResources.plannings.PLANNINGS}`),
        },
      );
    },
    [create, navigate],
  );

  if (isLoading) return <Loading />;

  return (
    <Create>
      <SimpleForm onSubmit={handleSubmit} defaultValues={defaultValues} mode="onBlur">
        {error && <Error error={error} />}
        <ArrayInput source="sites">
          <SimpleFormIterator
            inline
            fullWidth
            disableReordering
            disableClear
            removeButton={
              <Button variant="contained" color={"error"} startIcon={<DeleteIcon />}>
                Supprimer le site
              </Button>
            }
            addButton={
              <Button variant="contained" startIcon={<AddCircleOutlineIcon />}>
                ajouter un site
              </Button>
            }
          >
            <ReferenceInput source="site_id" reference="sites" validate={required()} perPage={100} />;
            <ArrayInput source="plannings">
              <SimpleFormIterator
                inline
                disableReordering
                disableClear
                removeButton={
                  <IconButton>
                    <DeleteIcon color="error" />
                  </IconButton>
                }
                addButton={
                  <Button variant="contained" startIcon={<AddCircleOutlineIcon />}>
                    ajouter un Planning
                  </Button>
                }
                sx={{
                  p: 2,
                  mb: 2,
                  boxShadow:
                    "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);",
                }}
              >
                <DateTimeInput source="start_date" validate={[required(), validatePlanningTime]} />
                <DateTimeInput source="end_date" validate={[required(), validatePlanningTime]} />
                <FormDataConsumer>
                  {({ scopedFormData, getSource, formData }: FormDataConsumerRenderParams) => {
                    if (!getSource) return null;
                    const index = +getSource("").split(".")[1];
                    const siteId = formData.sites[index]?.site_id;
                    return (
                      <>
                        {siteId ? (
                          <CountryDependingMode siteId={siteId} source={getSource("mode")} />
                        ) : (
                          <SelectInput source={getSource("mode")} choices={[]} disabled />
                        )}
                        <ModeDependentFields mode={scopedFormData.mode} getSource={getSource} />
                      </>
                    );
                  }}
                </FormDataConsumer>
                <NumberInput source={"reactivity_delay"} min={0} max={10} />
                <NumberInput source="soc_restoration_power" />
                <BooleanInput source="overidable" defaultValue={false} />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
}

const CountryDependingMode = ({ siteId, source }: { siteId: Identifier; source: string }) => {
  const { data, isLoading } = useGetOne(baseResources.sites.SITES, { id: siteId });

  const getModeDependingOnCountry = useCallback(() => {
    let mode;
    switch (data?.country) {
      case "France":
        mode = PLANNING_MODE.filter((mode) => mode !== "fingrid_fcrn");
        break;
      case "Finland":
        mode = PLANNING_MODE.filter((mode) => mode === "fingrid_fcrn");
        break;
      default:
        mode = PLANNING_MODE;
    }
    return mode.map((mode) => ({ id: mode, name: mode }));
  }, [data]);

  if (isLoading) return <Loading />;
  return <SelectInput source={source} choices={getModeDependingOnCountry()} validate={required()} />;
};

const ModeDependentFields = ({ mode, getSource }: { mode: string; getSource: (source: string) => string }) => {
  switch (mode) {
    case "soc_management":
      return <NumberInput source={getSource("target_soc")} min={0} max={1} />;

    case "fcr_rte_v2":
    case "fcr_rte_v2_no_reserve":
      return (
        <>
          <NumberInput source={getSource("fcr_engagement")} />
          <NumberInput source={getSource("target_soc")} min={0} max={1} />
          <NumberInput source={getSource("initial_soc_restoration_power")} />
          <SelectInput source={getSource("chronicle_filename")} choices={CHRONICLE_FILENAMES} />
        </>
      );

    case "power_chronicle":
      return <SelectInput source={getSource("chronicle_filename")} choices={CHRONICLE_FILENAMES} />;

    case "power":
      return <NumberInput source={getSource("target_power_mw")} min={0} max={1000} />;

    case "fingrid_fcrn": // TODO: add specific fields for fingrid_fcrn
      return (
        <>
          <NumberInput source={getSource("target_power_mw")} min={0} max={1000} />
          <NumberInput source={getSource("fcr_engagement")} />
          <NumberInput source={getSource("target_soc")} min={0} max={1} />
          <NumberInput source={getSource("initial_soc_restoration_power")} />
          <SelectInput source={getSource("chronicle_filename")} choices={CHRONICLE_FILENAMES} />
        </>
      );

    default:
      return null;
  }
};
