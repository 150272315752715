import { Alert, Box, Typography } from "@mui/material";
import axios from "axios";
import { validateEndDate, validatePlanningTime } from "components/validations";
import { baseResources } from "config_infos";
import { COUNTRIES, PLANNING_MASK_MODE } from "enums";
import { getDataproviderPydanticErrorMessage, parseBasePydanticErrorMessage } from "errors/handlers";
import useStatusAware from "hooks/useStatusAware";
import { useMemo, useState } from "react";
import {
  Create,
  DateTimeInput,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  required,
  useCreate,
  useDataProvider,
  useGetList,
  useNotify,
} from "react-admin";
import { useNavigate } from "react-router-dom";

export function useFetchSites(edpId: number) {
  return useGetList(baseResources.sites.GROUPS, {
    pagination: { page: 1, perPage: 100 },
    filter: { id: edpId },
    sort: { field: "id", order: "ASC" },
  });
}

export default function PlanningMaskCreate() {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const navigate = useNavigate();
  const { error: errors, setError: setErrors } = useStatusAware(false, []);
  const [create]: any = useCreate();
  const [selectedMode, setSelectedMode] = useState(null);

  const modeOptions = useMemo(
    () => [
      { id: "EDP", name: "EDP" },
      { id: "Site", name: "Site" },
    ],
    [],
  );

  const operatingModeOptions = PLANNING_MASK_MODE.map((operating_mode) => ({
    id: operating_mode,
    name: operating_mode,
  }));

  const countryOptions = useMemo(
    () => COUNTRIES.map(({ name, value }) => ({ id: value, name, disabled: value !== "fr" })),
    [],
  );

  const handleSubmit = async (values: any) => {
    setErrors([]);
    const { site_id, edp_id, mode, ...dataBody } = values;

    // on one site
    if (mode === "Site") {
      const siteData = {
        ...dataBody,
        site_id,
      };
      return await create(
        baseResources.plannings.PLANNING_MASKS,
        { data: siteData },
        {
          onSuccess: () => {
            notify("Planning mask created successfully", { type: "success" });
            navigate(`/${baseResources.plannings.PLANNING_MASKS}`);
          },
          onError: (error: any) => {
            const errorMessage = getDataproviderPydanticErrorMessage(error);
            setErrors([errorMessage]);
            notify(errorMessage, { type: "error" });
          },
        },
      );

      // on EDP
    } else {
      try {
        const { data: edp } = await dataProvider.getOne(baseResources.sites.GROUPS, { id: edp_id });

        const updatePromises = edp.site_ids.map(async (site_id: any) => {
          return axios.post(`${process.env.REACT_APP_PLANNINGS_API_URL}/${baseResources.plannings.PLANNING_MASKS}`, {
            ...dataBody,
            site_id,
          });
        });

        const results = await Promise.allSettled(updatePromises);

        results.forEach((result, index) => {
          if (result.status === "rejected") {
            const errorResponse = parseBasePydanticErrorMessage(result.reason.response.data.detail);
            const errorMessage = `Error updating site ${edp.site_ids[index]}: ${errorResponse}`;
            setErrors((prevState: string[]) => [...prevState, errorMessage]);
            notify(errorResponse, { type: "error" });
          }
        });
        navigate(`/${baseResources.plannings.PLANNING_MASKS}`);
      } catch (error) {
        console.error("Error fetching EDP:", error);
        notify(getDataproviderPydanticErrorMessage(error), { type: "error" });
      }
    }
  };

  return (
    <Create>
      {errors && errors.length > 1 && (
        <Alert severity="error" sx={{ mb: 2 }}>
          <Typography fontWeight={"bold"}>Error :</Typography>
          {errors.map((error: string, index: number) => (
            <Typography key={index}>{error}</Typography>
          ))}
        </Alert>
      )}

      <SimpleForm onSubmit={handleSubmit} mode="onBlur">
        <SelectInput source="country" choices={countryOptions} validate={required()} />

        <FormDataConsumer>
          {({ formData }: any) => {
            const isFrance = formData.country === "fr";
            return (
              <Box>
                {isFrance ? (
                  <Box display={"flex"} columnGap={2}>
                    <SelectInput
                      source="mode"
                      choices={modeOptions}
                      validate={required()}
                      onChange={(e) => setSelectedMode(e.target.value)}
                    />
                    <FormDataConsumer>
                      {() => {
                        if (selectedMode !== null) {
                          switch (selectedMode) {
                            case "EDP":
                              return (
                                <ReferenceInput
                                  source="edp_id"
                                  reference={baseResources.sites.GROUPS}
                                  filter={{ type: "rte_edp" }}
                                  perPage={100}
                                />
                              );
                            case "Site":
                              return (
                                <ReferenceInput source="site_id" reference={baseResources.sites.SITES} perPage={100} />
                              );
                          }
                        }
                      }}
                    </FormDataConsumer>

                    <DateTimeInput source="start_date" validate={[required(), validatePlanningTime]} />
                    <DateTimeInput source="end_date" validate={[required(), validatePlanningTime, validateEndDate]} />
                    <SelectInput source="operating_mode" choices={operatingModeOptions} validate={required()} />
                    <NumberInput source="operator_id" defaultValue={0} validate={required()} />
                  </Box>
                ) : null}
              </Box>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
}
